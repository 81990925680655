import { MESSAGE_TYPE } from 'shared/constants/messages';
import { applyPageFilters, sortComparator } from './helpers';

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

// getters
const getters = {
  canAddConversation: ({ allConversations }, _, { route }) => {
    return conversation_id => {
      const foldersId = route.params.id;
      const conversationPresent = allConversations.filter(
        c => c.id === conversation_id
      ).length;
      return !conversationPresent && foldersId > 0;
    };
  },
  getFoldersId({}, _, { route }) {
    const foldersId = route.params.id;
    return foldersId > 0 ? foldersId : null;
  },
  getAllConversations: (
    { allConversations, chatSortFilter: sortKey },
    _,
    __,
    { getUISettings }
  ) => {
    allConversations.forEach(conversation => {
      conversation.pinned = (getUISettings.pinnedConversations || []).includes(
        conversation.id
      );
    });

    return allConversations.sort((a, b) => sortComparator(a, b, sortKey));
  },
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getSelectedChatAttachments: (_state, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    return selectedChat.attachments || [];
  },
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const {
        content_attributes: contentAttributes = {},
        message_type: messageType,
      } = message;
      const { email = {} } = contentAttributes;
      const isIncomingOrOutgoing =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.INCOMING;
      if (email.from && isIncomingOrOutgoing) {
        return true;
      }
      return false;
    });

    return lastEmail;
  },
  getMineChats:
    (_state, { getAllConversations }, __, rootGetters) =>
    activeFilters => {
      const currentUserID = rootGetters.getCurrentUser?.id;

      return getAllConversations.filter(conversation => {
        const { assignee } = conversation.meta;
        const isAssignedToMe = assignee && assignee.id === currentUserID;
        const shouldFilter = applyPageFilters(conversation, activeFilters);
        const isChatMine = isAssignedToMe && shouldFilter;

        return isChatMine;
      });
    },
  // need to filer here
  getNewChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const unreadMsg = conversation.messages.filter(
        chat =>
          chat.created_at * 1000 > conversation.agent_last_seen_at * 1000 &&
          chat.message_type === 0 &&
          chat.private !== true
      ).length;

      // const isNew = unreadMsg > 0 ? true : false;
      const isNew = unreadMsg > 0 || conversation.unread_count > 0; // d99d

      const shouldFilter = applyPageFilters(conversation, activeFilters);

      return isNew && shouldFilter;
    });
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getUnAssignedChats:
    (_state, { getAllConversations }) =>
    activeFilters => {
      return getAllConversations.filter(conversation => {
        const isUnAssigned = !conversation.meta.assignee;
        const shouldFilter = applyPageFilters(conversation, activeFilters);
        return isUnAssigned && shouldFilter;
      });
    },
  getAllStatusChats:
    (_state, { getAllConversations }) =>
    activeFilters => {
      return getAllConversations.filter(conversation => {
        const shouldFilter = applyPageFilters(conversation, activeFilters);
        return shouldFilter;
      });
    },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    // todo why copypast
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  isManualUnread(_state) {
    // todo remove copypast
    const [m] = getSelectedChatConversation(_state);
    if (!m) return false;
    return (
      m.agent_last_seen_at - m.assignee_last_seen_at === 1 &&
      (m.messages.last().created_at - m.agent_last_seen_at) % 1000 === 0
    );
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getChatSortFilter: ({ chatSortFilter }) => chatSortFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return getAllConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },
};

export default getters;
