<template>
  <banner
    v-if="content && !disabled"
    color-scheme="alert"
    :banner-message="content"
    :background="description.color"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import Banner from 'dashboard/components/ui/Banner';

export default {
  components: { Banner },
  data() {
    return {
      descriptionKey: 'red_notice',
    };
  },
  computed: {
    ...mapGetters({
      getDescription: 'online99Extra/getDescription',
      uiFlags: 'online99Extra/getUIFlags',
    }),
    description() {
      return this.getDescription(this.descriptionKey);
    },
    disabled() {
      return this.description?.disabled === true;
    },
    content() {
      return this.description?.content;
    },
  },
  mounted() {
    this.$store.dispatch('online99Extra/show', this.descriptionKey);
  },
  methods: {},
};
</script>
