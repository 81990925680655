import ApiClient, {Utility} from './ApiClient';


export default new class extends ApiClient {
  constructor() {
    super('online_wa', { accountScoped: true });
  }

  async sendPlatformPsRequest(proc, data) {
    const {options, ...payload} = data || {}
    try {
      return await axios.post(`${this.url}/platform_ps_request`, payload, {params: {proc}, ...options});
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          bus.$emit('newToastMessage', WOOT.$root.$t('PLATFORM.API.FORBIDDEN_ERROR'));
          return null
        }
      }
      throw error
    }
  }

  async sendSettingToAssistance(data) {
    const {files, urls_array, keep, ...rest} = data
    const payload = new FormData();
    files?.forEach(file => payload.append('files[]', file))
    urls_array?.forEach(item => payload.append('urls_array[]', item))
    keep?.forEach(item => payload.append('keep[]', item ? JSON.stringify(item) : undefined))
    Utility.convertModelToFormData(rest, payload)
    return await axios.post(`${this.url}/gpt_request`, [...payload.entries()].length ? payload : undefined, {params: {proc: 'set_settings'}});
  }

  async getSettingToAssistance() {
    return await axios.post(`${this.url}/gpt_request`, undefined, {params: {proc: 'get_settings'}});
  }

  //-----not needed----//

  get() {
    throw 'not implemented'
  }

  show() {
    throw 'not implemented'
  }

  create() {
    throw 'not implemented'
  }

  update() {
    throw 'not implemented'
  }

  delete() {
    throw 'not implemented'
  }
}
